<template>
    <div class="page_ranking">

        <Popup/>
        <LeftMenu/>
        <Top/>

        <div class="ranking_info">

            <div class="ranking_title">
                <div class="title_a">CHEERS Loyalty Ranking List</div>
                <div class="title_b">CHEERS Points=Staked Amount * √Staking Time</div>
                <div class="title_c">CHEERS Points will keep updated every hour</div>
            </div>

            <div class="ranking_data_bj">
                <img src="../../assets/images/pc/feidie.png">
            </div>
            
            <div class="cheers_data_info">
                <div class="cheers_div">
                    <div class="cheers_title">CHEERS Price</div>
                    <div class="cheers_data">$ {{ setDecimal($store.state.systemInfo.cheersPrice, 6, false, false) }}</div>
                </div>
                <div class="cheers_div">
                    <div class="cheers_title">CHEERS in Circulation</div>
                    <div class="cheers_data">{{ setDecimal($store.state.systemInfo.cheersInCirculation, 3, false, false) }}</div>
                </div>
                <div class="cheers_div">
                    <div class="cheers_title">CHEERS Market Cap</div>
                    <div class="cheers_data">$ {{ setDecimal($store.state.systemInfo.cheersMarketCap, 3, false, false) }}</div>
                </div>
                <div class="cheers_div">
                    <div class="cheers_title">CHEERS Total Supply</div>
                    <div class="cheers_data">{{ setDecimal($store.state.systemInfo.cheersTotalSupply, 3, false, false) }}</div>
                </div>
                <div class="cheers_div">
                    <div class="cheers_title">Total Value Locked (TVL)</div>
                    <div class="cheers_data">$ {{ setDecimal($store.state.systemInfo.TotalValueLocked, 3, false, false) }}</div>
                </div>
            </div>

            <div class="ranking_select">
                <div class="select_top">
                    <div :class="topType === 1 ? 'top_index top_query' : 'top_index'" @click="setTop(1, 100)">TOP 100</div>
                    <div :class="topType === 2 ? 'top_index top_query' : 'top_index'" @click="setTop(2, 250)">TOP 250</div>
                    <div :class="topType === 3 ? 'top_index top_query' : 'top_index'" @click="setTop(3, 500)">TOP 500</div>
                </div>
                <div class="select_search">
                    <div class="search_button" @click="getRankingData"><img src="../../assets/images/pc/fangdajing.png"></div>
                    <input class="search_input" placeholder="Search Wallet" v-model="userAddress"/>
                </div>
            </div>

            <div class="ranking_table">
                <div class="table_top">
                    <div class="top_a">Ranking</div>
                    <div class="top_b">CHEERS Strong Holders</div>
                    <div class="top_c">Staking Amount</div>
                    <div class="top_d">Staking Time (Day)</div>
                    <div class="top_e">CHEERS Points</div>
                </div>
                <div class="table_row_data">
                    <div class="table_row" v-for="(obj, index) in rankingPageData" :key="index" v-if="rankingPageData.length > 0">
                        <div class="row_a">{{obj.ranking_num}}</div>
                        <div class="row_b">{{cutAccount(obj.cheers_address, 5)}}</div>
                        <div class="row_c">{{obj.cheers_power}}</div>
                        <div class="row_d">{{obj.cheers_date}}</div>
                        <div class="row_e">{{obj.cheers_score}}</div>
                    </div>
                    <div class="data_null" v-if="rankingPageData.length === 0">
                        <i v-if="!isQuery">No data . . .</i>
                        <i v-if="isQuery" class="el-icon-loading" style="font-size: 50px"/>
                        <i v-if="isQuery">Data query in progress . . .</i>
                    </div>
                </div>
            </div>

            <div class="ranking_pagination">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 25, 50]"
                        :page-size="pageTotal"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="allToTal">
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
    import Popup from "../../components/popup/Index";
    import LeftMenu from "../../components/frames/LeftMenu";
    import Top from "../../components/frames/Top";
    import toolUtils from "../../tools/toolUtils";
    import {cutAccount} from "../../tools";

    export default {
        name: "Index",
        components: {Popup, LeftMenu, Top},
        data() {
            return {
                topType: 1,
                totalData: 100,
                currentPage: 1,
                pageTotal: 10,
                allToTal: 100,
                isQuery: false,
                queryType: 'cheers',
                userAddress: null,
                rankingAllData: [],
                rankingPageData: []
            }
        },
        watch: {
        },
        created() {
        },
        mounted() {
            this.getRankingData()
        },
        beforeDestroy() {
        },
        methods: {
            cutAccount(account, num) {
                return cutAccount(account, num, true);
            },
            setDecimal(numVal, numDecimalMax, roundUp, science) {
                return toolUtils.setDecimal(numVal, numDecimalMax, roundUp, science)
            },
            setTop(val, total) {
                this.topType = parseInt(val)
                this.totalData = parseInt(total)
                this.getRankingData()
            },
            handleSizeChange(val) {
                this.pageTotal = parseInt(val)
                console.log(`每页 ${val} 条`);
                this.setRankingData()
            },
            handleCurrentChange(val) {
                this.currentPage = parseInt(val)
                console.log(`当前页: ${val}`);
                this.setRankingData()
            },
            setRankingData() {
                if (this.rankingAllData.length === 0) {
                    this.allToTal = 0
                    this.getRankingData()
                } else {
                    let start = (this.currentPage - 1) * this.pageTotal
                    let end = this.currentPage * this.pageTotal
                    if (parseInt(end) > parseInt(this.rankingAllData.length)) {
                        end = this.rankingAllData.length
                    }
                    this.rankingPageData = []
                    for (let i = start; i < end; i++) {

                        if (this.topType === 1) {
                            this.allToTal = this.rankingAllData.length >= 100 ? 100 : this.rankingAllData.length
                            if (i >= 100) {
                                break
                            }
                        } else if (this.topType === 2) {
                            this.allToTal = this.rankingAllData.length >= 250 ? 250 : this.rankingAllData.length
                            if (i >= 250) {
                                break
                            }
                        } else if (this.topType === 3) {
                            this.allToTal = this.rankingAllData.length >= 500 ? 500 : this.rankingAllData.length
                            if (i >= 500) {
                                break
                            }
                        }

                        let obj = {
                            ranking_num: i + 1,
                            cheers_address: this.rankingAllData[i].cheers_address,
                            cheers_power: this.rankingAllData[i].cheers_power,
                            cheers_date: this.rankingAllData[i].cheers_date,
                            cheers_score: this.rankingAllData[i].cheers_score
                        }
                        this.rankingPageData.push(obj)
                    }
                }
            },
            getRankingData() {
                this.isQuery = true
                this.rankingPageData = []
                this.$http.queryRanking(
                    this.queryType,
                    this.totalData,
                    this.userAddress
                ).then(res => {
                    this.rankingAllData = res
                    setTimeout(() => {
                        if (this.rankingAllData.length > 0) {
                            this.currentPage = 1
                            this.pageTotal = 10
                            this.setRankingData()
                            this.$message.warning('Ranking data query succeeded!')
                        } else {
                            this.allToTal = 0
                            this.currentPage = 1
                            this.pageTotal = 10
                            this.rankingPageData = []
                            this.$message.warning('No relevant data found!')
                        }
                        this.isQuery = false
                    }, 500)
                }).catch(err => {
                    this.isQuery = false
                    console.log("err: ", err);
                    this.$message.error('Service error!')
                })
            }
        }
    }
</script>

<style scoped lang="less">
.page_ranking {
    .ranking_info::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .ranking_info {
        @media screen and (max-width: 768px) {
            position: fixed;
            zoom: 2;
            width: 100%;
            min-width: 320px;
            top: 120px;
            left: 0;
        }
        position: absolute;
        bottom: 0;
        top: 120px;
        right: 0;
        left: 260px;
        color: #FFFFFF;
        min-width: 820px;
        min-height: 611px;
        overflow-y: auto;
        background-color: #0e1429;
        background-image: url("../../assets/images/pc/bg_all.png");
        background-size: 100% auto;
        .ranking_data_bj {
            @media screen and (max-width: 768px) {
                display: none;
            }
            display: block;
            position: absolute;
            left: 25%;
            while: 215px;
            height: 150px;
            img {
                margin-top: 20px;
                width: 215px;
            }
        }
        .ranking_title {
            text-align: center;
            margin-top: 10px;
            .title_a {
                font-size: 36px;
                font-family: Poppins;
                font-weight: bold;
                color: #ECB451;
                line-height: 54px;
            }
            .title_b {
                margin-top: 10px;
                font-size: 22px;
                font-family: Poppins;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 26px;
            }
            .title_c {
                margin-top: 5px;
                font-size: 20px;
                font-family: Poppins;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 26px;
            }
        }
        .cheers_data_info {
            @media screen and (max-width: 768px) {
                height: 500px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                zoom: 1.5;
                background: none;
                border: 1px solid #E19A00;
            }
            margin: 20px auto;
            height: 150px;
            width: 90%;
            background-color: #E19A00;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-evenly;
            align-items: center;
            .cheers_div {
                text-align: center;
                .cheers_title {
                    @media screen and (max-width: 768px) {
                        color: #E19A00;
                    }
                    font-size: 16px;
                    font-weight: 600;
                }
                .cheers_data {
                    margin-top: 15px;
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }
        .ranking_select {
            @media screen and (max-width: 768px) {
                zoom: 1.2;
            }
            width: 90%;
            margin: 10px auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            .select_top {
                width: 300px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-around;
                align-items: center;
                .top_index {
                    cursor: pointer;
                    text-align: center;
                    font-weight: 600;
                    width: 80px;
                    border-bottom: 1px solid #cecece;
                }
                .top_query {
                    border-bottom: 1px solid #E19A00;
                }
            }
            .select_search {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                width: 250px;
                height: 40px;
                background: #2a2a2a;
                opacity: 0.37;
                border-radius: 23px;
                .search_input {
                    height: 40px;
                    border-style: none;
                    background: none;
                }
                .search_button {
                    cursor: pointer;
                    img {
                        width: 30px;
                        height: 30px;
                        margin: 5px 10px 0 10px;
                    }
                }
            }
        }
        .ranking_table {
            width: 90%;
            height: 365px;
            margin: 10px auto;
            @media screen and (max-width: 768px) {
                zoom: 1.3;
            }
            .table_top {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-around;
                height: 50px;
                font-weight: 600;
                background-color: #E19A00;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                .top_a {
                    width: 80px;
                }
                .top_b {
                    width: 250px;
                }
                .top_c {
                    display: block;
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                    width: 150px;
                }
                .top_d {
                    display: block;
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                    width: 150px;
                }
                .top_e {
                    display: block;
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                    width: 180px;
                }
            }
            .table_row_data::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            .table_row_data {
                width: 100%;
                height: 310px;
                background-color: #404040;
                overflow-y: auto;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                display: block;
                @media screen and (max-width: 768px) {
                    height: 316px;
                }
                .table_row {
                    height: 30px;
                    background-color: #404040;
                    border-bottom: 1px solid #E19A00;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-around;
                    .row_a {
                        text-align: center;
                        width: 80px;
                    }
                    .row_b {
                        width: 250px;
                        color: #E19A00;
                    }
                    .row_c {
                        display: block;
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                        width: 150px;
                        color: #E19A00;
                    }
                    .row_d {
                        display: block;
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                        width: 150px;
                        color: #E19A00;
                    }
                    .row_e {
                        display: block;
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                        width: 180px;
                        color: #E19A00;
                    }
                }
                .data_null {
                    text-align: center;
                    font-size: 32px;
                    line-height: 308px;
                    color: #E19A00;
                    border: 1px solid #E19A00;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    @media screen and (max-width: 768px) {
                        line-height: 313px;
                    }
                }
            }
        }
        .ranking_pagination {
            @media screen and (max-width: 768px) {
                zoom: 1.3;
            }
            width: 90%;
            margin: 25px auto;
        }
    }
}
</style>

<style lang="less">
    .el-pager li:hover {
        color: #E19A00 !important;
    }
    .el-pager li.active {
        color: #E19A00 !important;
    }
    .el-select-dropdown__item.selected {
        color: #E19A00 !important;
    }
    .el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon:hover {
        color: #E19A00 !important;
    }
    .el-pagination .el-select .el-input .el-input__inner:hover {
        color: #E19A00 !important;
    }
    .el-pagination .el-select .el-input .el-input__inner {
        border: 1px solid #c9c9c9 !important;
    }
    .el-pagination .el-select .el-input .el-input__inner:hover {
        border: 1px solid #E19A00 !important;
    }
    .el-pagination__editor.el-input .el-input__inner {
        border: 1px solid #c9c9c9 !important;
    }
    .el-pagination__editor.el-input .el-input__inner:hover {
        border: 1px solid #E19A00 !important;
    }
    .el-pagination__sizes {
        display: block;
        @media screen and (max-width: 768px) {
            display: none !important;
        }
    }
</style>